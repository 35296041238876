import React, { Component } from 'react'; import { h } from 'preact';
import styles from './WebsiteColumn.module.css';
import propTypes from 'prop-types';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import Option from '../Option/Option';
import Highlight from '../Highlight/Highlight';
import Feature from '../Feature/Feature';
import Slider, { Range } from 'rc-slider';
import get from 'lodash/get';
import set from 'lodash/set';
import FinalPricing from '../FinalPricing/FinalPricing';
import { download } from '../../services/kostenindicatie';
import { getPrice } from '../../lib/pricing';
import globalStyles from '../../index.module.css';
import config from '../../config'
import { supplant } from '../../_locales';
import { capatalize, decamalize } from '../../lib/util';
import { createText } from '../../lib/markdown'

const { currency, website: { websiteColor, titleColor, base, monthlyPayments, amounts, value } } = config

class WebsiteColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value,
            subscriptionRatio: config.defaultSubscriptionRatio
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    onSliderChange = path => value => {
        console.log(value)
        this.setState(prevState => {
            set(prevState, path, value);
            console.log(prevState, path, value)
            return prevState;
        });
    }

    onChange = path => e => {
        const { value } = e.target;
        this.setState(prevState => {
            set(prevState, path, value)
            console.log(prevState)
            return prevState;
        });
    }

    download = async () => {
        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, this.state.subscriptionRatio)
        const messages = get(this, 'props.messages', {})
        const monthlyCostsDisclaimerTemplate = get(this, 'props.messages.monthlyCostsDisclaimer')
        const monthlyCostsDisclaimer = supplant(monthlyCostsDisclaimerTemplate, { currency, monthly })
        const text = createText(messages, value, 'website');
        let body = '';
        if (monthly) body += monthlyCostsDisclaimer;
        body += '\n\n'
        body += text;
        const promise = download({ price: amount, body });
        this.props.onLoading(promise, get(this, 'props.messages.downloadingKostenindicatie'));

    }

    onCheckboxChange = path => e => {
        const { checked } = e.target;
        console.log(checked);
        this.setState(prevState => {
            set(prevState, path, checked)
            if (prevState.blogging === false) prevState.pushNotifications = false;
            return prevState;
        });
    }

    render() {
        const { value, subscriptionRatio } = this.state;

        let complexityLabel = get(this, 'props.messages.templateWithoutCustomizations')
        if (value.complexity >= 20) {
          complexityLabel = get(this, 'props.messages.templateWithLittleCustomizations')
        } if (value.complexity >= 40) {
          complexityLabel = get(this, 'props.messages.customizedTemplate')
        } if (value.complexity >= 60) {
          complexityLabel = get(this, 'props.messages.websiteDesignedWithoutTemplate')
        } if (value.complexity >= 80) {
          complexityLabel = get(this, 'props.messages.aCustomWebsite')
        }

        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, subscriptionRatio)
        const { monthly: subscriptionMonthly } = getPrice(amounts, currency, value, monthlyPayments, base, 1)
        const monthlyRatioSteps = 1 / Math.min(subscriptionMonthly, 100)

        return (
            <div className={styles.WebsiteColumn}>
                <div className={globalStyles.inner}>
                    <ColumnHeader style={{ backgroundColor: websiteColor, color: titleColor }} title={get(this, 'props.messages.websiteTitle')} subTitle={get(this, 'props.messages.websiteSubTitle')} />
                    <div className={globalStyles.scrollPaneShadow} >

                        <div className={globalStyles.scrollPane}>

                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>Wordpress</Highlight> CMS<br />
                                <small>{get(this, 'props.messages.buildAWebsiteOnAnotherPlatform')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.unlimited')}</Highlight> {get(this, 'props.messages.subdomains')}
                                <br /><small>{get(this, 'props.messages.unlimitedSubdomainsDescription')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.unlimitedPlural')}</Highlight> {get(this, 'props.messages.bandwith')}
                                <br /><small>{get(this, 'props.messages.unlimitedBandwithDescription')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                {get(this, 'props.messages.basic')}- <Highlight>{get(this, 'props.messages.spam')}</Highlight> {get(this, 'props.messages.protection')}
                                <br />
                                <small>{get(this, 'props.messages.free')} {get(this, 'props.messages.spamProtection')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.unlimited')}</Highlight> {get(this, 'props.messages.ssdStorage')}
                                <br />
                                <small>{get(this, 'props.messages.ssdStorageDescription')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>SSL</Highlight> {get(this, 'props.messages.certificate')}
                                <br />
                                <small>{get(this, 'props.messages.trafficIsProtectedForFree')}</small>
                            </Feature>
                            <Option>
                                <small>{currency}{amounts.logoDesign},-</small><br />
                                <label>{get(this, 'props.messages.websiteLogoDesign')}</label>
                                <input type="checkbox" checked={value.logoDesign} onChange={this.onCheckboxChange('value.logoDesign')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.seo},-</small><br />
                                <label>{get(this, 'props.messages.websiteSeo')}</label>
                                <input type="checkbox" checked={value.seo} onChange={this.onCheckboxChange('value.seo')} />
                                <br /><small>{get(this, 'props.messages.improvedSearchability')}.</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.socialMediaManagment},-</small><br />
                                <label>{get(this, 'props.messages.websiteSocialMediaManagment')}</label>
                                <input type="checkbox" checked={value.socialMediaManagment} onChange={this.onCheckboxChange('value.socialMediaManagment')} />
                                <br />
                                <small>{get(this, 'props.messages.managedSocialMedia')}</small>{' '}
                                <small>{get(this, 'props.messages.managedSocialMediaExternalExperts')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.googleBusiness},-</small><br />
                                <label>{get(this, 'props.messages.websiteGoogleBusiness')}</label>
                                <input type="checkbox" checked={value.googleBusiness} onChange={this.onCheckboxChange('value.googleBusiness')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.mailingList},-</small><br />
                                <label>{get(this, 'props.messages.websiteMailingList')}</label>
                                <input type="checkbox" checked={value.mailingList} onChange={this.onCheckboxChange('value.mailingList')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.emailTemplate},-</small><br />
                                <small>{get(this, 'props.messages.newsLettersAndAutomaticEmails')}</small><br />
                                <label>{get(this, 'props.messages.websiteEmailTemplate')}</label>
                                <input type="checkbox" checked={value.emailTemplate} onChange={this.onCheckboxChange('value.emailTemplate')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.blogging},-</small><br />
                                <label>{get(this, 'props.messages.websiteBlogging')}</label>
                                <input type="checkbox" checked={value.blogging} onChange={this.onCheckboxChange('value.blogging')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.emailSignature},-</small><br />
                                <label>{get(this, 'props.messages.advancedEmailSignature')}</label>
                                <input type="checkbox" checked={value.emailSignature} onChange={this.onCheckboxChange('value.emailSignature')} label='Email handtekening' />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.virtualAssisentChat},-</small><br />
                                <label>{get(this, 'props.messages.websiteVirtualAssisentChat')}</label>
                                <input type="checkbox" checked={value.virtualAssisentChat} onChange={this.onCheckboxChange('value.virtualAssisentChat')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.advancedSpamProtection},- / {get(this, 'props.messages.perMonth')}</small><br />
                                <label>{get(this, 'props.messages.advancedSpamProtection')}</label>
                                <input type="checkbox" checked={value.advancedSpamProtection} onChange={this.onCheckboxChange('value.advancedSpamProtection')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.customDomainEmail},-</small><br />
                                <label>{get(this, 'props.messages.customDomainEmail')}</label>
                                <input type="checkbox" checked={value.customDomainEmail} onChange={this.onCheckboxChange('value.customDomainEmail')} />
                            </Option>
                            <Option>
                                <small>{currency}{amounts.complexity * 20},- / {get(this, 'props.messages.perStep')}</small><br />
                                <label>Complexiteit</label>
                                <Slider step={20} min={0} max={100} value={value.complexity} onChange={this.onSliderChange('value.complexity')} />
                                <output>{complexityLabel}</output> <small>{get(this, 'props.messages.websiteComplexityDescription')}</small>
                            </Option>
                            {value.blogging
                                ? <Option>
                                    <small>{currency}{amounts.pushNotifications},-</small><br />
                                    <label>Push Notifications</label>
                                    <input type="checkbox" checked={value.pushNotifications} onChange={this.onCheckboxChange('value.pushNotifications')} />
                                    <br /><small>{get(this, 'props.messages.sendUsersNotifications')}</small>
                                </Option>
                                : ''}
                        </div>

                    </div>
                </div>
                <div className={globalStyles.inner}>

                    <div className={globalStyles.padded}>
                        <br /><br />

                        <div>
                            {get(this, 'props.messages.supportAndExtraWorkPayment')}
                        </div>

                        <br />

                        <div>
                            {get(this, 'props.messages.thirdPartyCosts')}
                        </div>

                        <br /><br />

                        <div>
                            {get(this, 'props.messages.divergentRequest')}
                        </div>

                        <Option>
                            <label>{get(this, 'props.messages.prepaid')} / {get(this, 'props.messages.subscriptionBased')}</label>
                            <br />
                            <Slider min={0} max={1} step={monthlyRatioSteps} value={subscriptionRatio} onChange={this.onSliderChange('subscriptionRatio')} />
                            <output>{Math.round(subscriptionRatio * 100)} % {get(this, 'props.messages.subscriptionBased')}</output>
                        </Option>

                        <FinalPricing
                            messages={this.props.messages}
                            amount={amount}
                            amounts={amounts}
                            monthlyPayments={monthlyPayments}
                            base={base}
                            value={value}
                            monthly={monthly}
                            currency={currency} />

                        <button className={globalStyles.button} onClick={this.download}>
                            {get(this, 'props.messages.costIndication')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

WebsiteColumn.propTypes = {
    messages: propTypes.object.isRequired,
    onLoading: propTypes.func.isRequired
}

WebsiteColumn.defaultProps = {

}

export default WebsiteColumn;
