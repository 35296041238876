import React, { Component } from 'react'; import { h } from 'preact';
import globalStyles from '../../index.module.css';

class Option extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { title, children } = this.props;

        return (
            <div className={globalStyles.Option}>
                <h5>{title}</h5>
                {children}
            </div>
        );
    }
}

Option.propTypes = {

}

Option.defaultProps = {

}

export default Option;
