import get from 'lodash/get';
import { capatalize, decamalize } from './util';

export const createText = (messages, value, type) => {
  const { yes, no } = messages
  const keys = Object.keys(value)
  keys.unshift('allFreeModules');
  value.allFreeModules = yes;
  const col1 = keys.slice(0, Math.ceil(keys.length / 2))
  const col2 = keys.slice(Math.ceil(keys.length / 2))

  let text = '|' + get(messages, 'name') + '||||' + get(messages, 'name') + '|||\n'
  text += '|:---|---|---:|---|:---|---|---:|\n';
  for (let i = 0; i < col1.length; i++) {
      const key1 = col1[i]
      let val1 = value[key1];
      if (typeof val1 === 'boolean') {
          val1 = val1 ? yes : no
      }
      const msg1 = messages[type + capatalize(key1)] || decamalize(key1)
      text += '|' + msg1.replace('(%)', '');
      text += ':|&nbsp;&nbsp;&nbsp;&nbsp;|'
      text += val1
      if (msg1.includes('%')) text += '%'
      const key2 = col2[i]
      if (!key2) break;
      let val2 = value[key2];
      if (typeof val2 === 'boolean') {
          val2 = val2 ? yes : no
      }
      const msg2 = messages[type + capatalize(key2)] || decamalize(key2)
      text += '|&nbsp;&nbsp;&nbsp;&nbsp;|' + msg2.replace('(%)', '');
      text += ':|&nbsp;&nbsp;&nbsp;&nbsp;|'
      text += val2
      if (msg2.includes('%')) text += '%'
      text += '|\n'
  }
  text += '\n'

  return text;
}
