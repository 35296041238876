import React from 'react'
import { h } from 'preact'
import styles from './Loader.module.css'
import propTypes from 'prop-types'

const cl = (...args) => args.filter(Boolean).join(' ')

const Loader = props => {
    return (
        <div {...props} className={cl(styles.Loader, props.className)} />
    )
}

Loader.propTypes = {
}

Loader.defaultValues = {
}

export default Loader
