import React, { Component } from 'react'; import { h } from 'preact';
import styles from './Highlight.module.css';
import propTypes from 'prop-types';






class Highlight extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <span className={styles.Highlight}>
                {this.props.children}
            </span>
        );
    }
}

Highlight.propTypes = {

}

Highlight.defaultProps = {

}

export default Highlight;
