import logo from './logo.svg';
import appStyles from './App.module.css';
import WebsiteColumn from './components/WebsiteColumn/WebsiteColumn';
import AppColumn from './components/AppColumn/AppColumn';
import DesktopColumn from './components/DesktopColumn/DesktopColumn';
import Overlay from './components/Overlay/Overlay';
import styles from './index.module.css'
import { defaultLocale, loadLocale } from './_locales'
import Loader from './components/Loader/Loader';
import config from './config'
import get from 'lodash/get';
import React, { Component } from 'react'; import { h } from 'preact';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: defaultLocale,
      isLoading: false
    }
  }

  componentDidMount() {
    loadLocale(document.documentElement.lang)
      .then(messages => {
        this.setState({
          messages
        })
      })
  }

  render() {
    const { messages, isLoading } = this.state

    const onLoading = (promise, message = true) => {
      this.setState({
        isLoading: message
      })
      promise.catch(err => alert(err.toString()))
        .then(() => {
          this.setState({
            isLoading: false
          })
        })
    }

    const overlay = (
      <>
        <Loader style={{ fontSize: '4em', color: config["loaderColor"] }} />
        <h2 className={appStyles.loaderTitle}>{get(messages, 'loadingTitle')}</h2>
        <p className={appStyles.loaderSubtitle}>{isLoading}</p>
      </>
    )

    return (
      <div className={appStyles.App}>
        <Overlay active={!!isLoading} overlay={overlay}>
          <div className={styles.container}>
            <WebsiteColumn onLoading={onLoading} messages={messages} />
            <AppColumn onLoading={onLoading} messages={messages} />
            <DesktopColumn onLoading={onLoading} messages={messages} />
          </div>
        </Overlay>
      </div>
    );
  }
}

export default App;
