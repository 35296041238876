import React, { Component } from 'react'; import { h } from 'preact';
import styles from './DesktopColumn.module.css';
import propTypes from 'prop-types';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import Option from '../Option/Option';
import Highlight from '../Highlight/Highlight';
import Feature from '../Feature/Feature';
import Slider, { Range } from 'rc-slider';
import get from 'lodash/get';
import set from 'lodash/set';
import FinalPricing from '../FinalPricing/FinalPricing';
import { download } from '../../services/kostenindicatie';
import { getPrice } from '../../lib/pricing';
import globalStyles from '../../index.module.css';
import config from '../../config'
import { supplant } from '../../_locales';
import { decamalize, capatalize } from '../../lib/util';
import { createText } from '../../lib/markdown'

const { currency, desktop: { titleColor, monthlyPayments, desktopColor, base, value, amounts } } = config

class DesktopColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value,
            subscriptionRatio: config.defaultSubscriptionRatio
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    onSliderChange = path => value => {
        console.log(value)
        this.setState(prevState => {
            set(prevState, path, value);
            console.log(prevState, path, value)
            return prevState;
        });
    }

    onChange = path => e => {
        const { value } = e.target;
        this.setState(prevState => {
            set(prevState, path, value)
            console.log(prevState)
            return prevState;
        });
    }

    download = async () => {
        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, this.state.subscriptionRatio)
        const messages = get(this, 'props.messages', {})
        const monthlyCostsDisclaimerTemplate = get(this, 'props.messages.monthlyCostsDisclaimer')
        const monthlyCostsDisclaimer = supplant(monthlyCostsDisclaimerTemplate, { currency, monthly })
        const text = createText(messages, value, 'desktop')

        let body = '';
        if (monthly) body += monthlyCostsDisclaimer;
        body += '\n\n'
        body += text;

        this.props.onLoading(download({ price: amount, body }), get(this, 'props.messages.downloadingKostenindicatie'));
    }

    onCheckboxChange = path => e => {
        const { checked } = e.target;
        console.log(checked);
        this.setState(prevState => {
            set(prevState, path, checked)
            if (prevState.value.alreadyHaveAnApp === true) {
                Object.assign(prevState.value, {
                    complexity: 0,
                    wordpressSite: false,
                    pushNotifications: false,
                    native: false,
                    database: false,
                    auth: false,
                    realTimeUpdates: false,
                    chat: false,
                    camera: false,
                    bluetooth: false,
                    googleMaps: false,
                    serviceIntegrations: 0,
                    backgroundRunning: false,
                    geolocation: false,
                    microsoftStore: false,
                    inAppPurchases: false,
                    productDevelopment: false,
                    customFeatures: 0,
                })
            }
            return prevState;
        });
    }

    render() {
        const { value, subscriptionRatio } = this.state;

        let complexityLabel = get(this, 'props.messages.templateWithoutChanges')
        if (value.complexity >= 20) {
            complexityLabel = get(this, 'props.messages.templateWithASmallAmountOfChanges')
        } if (value.complexity >= 40) {
            complexityLabel = get(this, 'props.messages.aCustomAppDesignedFromATemplate')
        } if (value.complexity >= 60) {
            complexityLabel = get(this, 'props.messages.ACustomAppDesignedWithoutATemplate')
        } if (value.complexity >= 80) {
            complexityLabel = get(this, 'props.messages.fullyCustomApp')
        }

        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, subscriptionRatio)
        const { monthly: subscriptionMonthly } = getPrice(amounts, currency, value, monthlyPayments, base, 1)
        const monthlyRatioSteps = 1 / Math.min(subscriptionMonthly, 100)

        return (
            <div className={styles.DesktopColumn}>
                <div className={globalStyles.inner}>

                    <ColumnHeader style={{ backgroundColor: desktopColor, color: titleColor }} title={get(this, 'props.messages.desktopTitle')} subTitle={get(this, 'props.messages.desktopSubTitle')} />

                    <div className={globalStyles.scrollPaneShadow} >

                        <div className={globalStyles.scrollPane}>

                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.privacy')}</Highlight> {get(this, 'props.messages.policy')}<br />
                                <small>{get(this, 'props.messages.dontWorryAboutWritingYourOwnPrivacyPoliciy')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>Windows</Highlight> deployment.
                        <br />
                                <small>{get(this, 'props.messages.distributeWindowsFiles')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.automatic')}</Highlight> {get(this, 'props.messages.updates')}.
                    </Feature>
                            <Option>
                                <small>{currency}{amounts.iosDeployment},-</small><br />
                                <label>{get(this, 'props.messages.ioSDeployment')}</label>
                                <input type="checkbox" checked={value.iosDeployment} onChange={this.onCheckboxChange('value.iosDeployment')} />
                                <br /><small>{get(this, 'props.messages.distributeIosFiles')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.linuxDeployment},-</small><br />
                                <label>{get(this, 'props.messages.linuxDeployment')}</label>
                                <input type="checkbox" checked={value.linuxDeployment} onChange={this.onCheckboxChange('value.linuxDeployment')} />
                                <br /><small>{get(this, 'props.messages.distributeLinuxFiles')}</small>
                            </Option>
                            <Option>
                                <label>{get(this, 'props.messages.desktopAlreadyHaveAnApp')}</label>
                                <input type="checkbox" checked={value.alreadyHaveAnApp} onChange={this.onCheckboxChange('value.alreadyHaveAnApp')} /><br />
                                <small>{get(this, 'props.messages.doYouWantToPortThisAppOverToDesktop')}</small>
                            </Option>
                            {
                                value.alreadyHaveAnApp
                                    ? null
                                    : <><Option>
                                        <small>{currency}{amounts.wordpressSite},-</small><br />
                                        <label>{get(this, 'props.messages.desktopWordpressSite')}</label>
                                        <input type="checkbox" checked={value.wordpressSite} onChange={this.onCheckboxChange('value.wordpressSite')} />
                                    </Option>
                                        <Option>
                                            <small>{currency}{amounts.pushNotifications},-</small><br />
                                            <label>{get(this, 'props.messages.desktopPushNotifications')}</label>
                                            <input type="checkbox" checked={value.pushNotifications} onChange={this.onCheckboxChange('value.pushNotifications')} />
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.native},-</small><br />
                                            <label>{get(this, 'props.messages.desktopNative')}</label>
                                            <input type="checkbox" checked={value.native} onChange={this.onCheckboxChange('value.native')} />
                                            <br /> <small>{get(this, 'props.messages.nativeDesktopAppDescription')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.database},-</small><br />
                                            <label>{get(this, 'props.messages.desktopDatabase')}</label>
                                            <input type="checkbox" checked={value.database} onChange={this.onCheckboxChange('value.database')} />
                                            <br /> <small>{get(this, 'props.messages.storeDataOnServer')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.auth},-</small><br />
                                            <label>{get(this, 'props.messages.desktopAuth')}</label>
                                            <input type="checkbox" checked={value.auth} onChange={this.onCheckboxChange('value.auth')} />
                                            <br /> <small>{get(this, 'props.messages.desktopAuthDescription')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.realTimeUpdates},-</small><br />
                                            <label>{get(this, 'props.messages.desktopRealTimeUpdates')}</label>
                                            <input type="checkbox" checked={value.realTimeUpdates} onChange={this.onCheckboxChange('value.realTimeUpdates')} />
                                            <br /> <small>{get(this, 'props.messages.desktopAuthDescription')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.chat},-</small><br />
                                            <label>{get(this, 'props.messages.desktopChat')}</label>
                                            <input type="checkbox" checked={value.chat} onChange={this.onCheckboxChange('value.chat')} />
                                            <br /> <small>{get(this, 'props.messages.realtimeUpdatesAndAuthRequired.message')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.camera},-</small><br />
                                            <label>{get(this, 'props.messages.desktopCamera')}</label>
                                            <input type="checkbox" checked={value.camera} onChange={this.onCheckboxChange('value.camera')} />
                                            <br /> <small>{get(this, 'props.messages.desktopWebcamDescription.message')}</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.bluetooth},-</small><br />
                                            <label>{get(this, 'props.messages.desktopBluetooth')}</label>
                                            <input type="checkbox" checked={value.bluetooth} onChange={this.onCheckboxChange('value.bluetooth')} />
                                            <br /> <small>Maak een app die Bluetooth kan gebruiken om verbinding te maken met andere apparaten.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.googleMaps},-</small><br />
                                            <label>{get(this, 'props.messages.desktopGoogleMaps')}</label>
                                            <input type="checkbox" checked={value.googleMaps} onChange={this.onCheckboxChange('value.googleMaps')} label='Maps' />
                                            <br /> <small>Gebruik google maps in uw app.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.backgroundRunning},-</small><br />
                                            <label>{get(this, 'props.messages.desktopBackgroundRunning')}</label>
                                            <input type="checkbox" checked={value.backgroundRunning} onChange={this.onCheckboxChange('value.backgroundRunning')} label='Achtergrondprocessen' />
                                            <br /> <small>Achtergrondtaken uitvoeren op het gebruikersapparaat.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.geolocation},-</small><br />
                                            <label>{get(this, 'props.messages.desktopGeolocation')}</label>
                                            <input type="checkbox" checked={value.geolocation} onChange={this.onCheckboxChange('value.geolocation')} label='Geolocation' />
                                            <br /> <small>Gebruik de GPS-positie van de gebruiker in uw app.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.microsoftStore},-</small><br />
                                            <label>{get(this, 'props.messages.desktopMicrosoftStore')}</label>
                                            <input type="checkbox" checked={value.microsoftStore} onChange={this.onCheckboxChange('value.microsoftStore')} label='Microsoft Store' />
                                            <br /> <small>Deploy in de Microsoft Store.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.inAppPurchases},-</small><br />
                                            <label>{get(this, 'props.messages.desktopInAppPurchases')}</label>
                                            <input type="checkbox" checked={value.inAppPurchases} onChange={this.onCheckboxChange('value.inAppPurchases')} label='In-app purchases' />
                                            <br /> <small>Gebruikers toestaan ​​items in uw app te kopen.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.productDevelopment},-</small><br />
                                            <label>{get(this, 'props.messages.desktopProductDevelopment')}</label>
                                            <input type="checkbox" checked={value.productDevelopment} onChange={this.onCheckboxChange('value.productDevelopment')} label='Product development' />
                                            <br /> <small>Heb je alleen een ruw idee voor een app? Ik kan je helpen om je app helemaal vanaf het begin op te bouwen.</small>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.serviceIntegrations},- / per integratie</small><br />
                                            <label>{get(this, 'props.messages.desktopServiceIntegrations')}</label>
                                            <Slider min={0} max={10} value={value.serviceIntegrations} onChange={this.onSliderChange('value.serviceIntegrations')} />
                                            <output>{value.serviceIntegrations}</output>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.complexity * 20},- / {get(this, 'props.messages.perStep')}</small><br />
                                            <label>{get(this, 'props.messages.complexity')}</label>
                                            <Slider step={20} min={0} max={100} value={value.complexity} onChange={this.onSliderChange('value.complexity')} />
                                            <output>{complexityLabel}</output>
                                        </Option>
                                        <Option>
                                            <small>{currency}{amounts.customFeatures},- / per functie</small><br />
                                            <label>{get(this, 'props.messages.desktopCustomFeatures')}</label><br />
                                            <small>{get(this, 'props.messages.exampleOfFeatures')}</small>
                                            <Slider min={0} max={10} value={value.customFeatures} onChange={this.onSliderChange('value.customFeatures')} />
                                            <output>{value.customFeatures === 10 ? <Highlight>Onbeperkt</Highlight> : value.customFeatures} aangepaste functies.</output>
                                        </Option></>
                            }

                        </div>
                    </div>

                </div>
                <div className={globalStyles.inner}>

                    <div className={globalStyles.padded}>
                        <br /><br />

                        <div>
                            {get(this, 'props.messages.supportAndExtraWorkPayment')}
                        </div>

                        <br />

                        <div>
                            {get(this, 'props.messages.thirdPartyCosts')}
                        </div>

                        <br /><br />

                        <div>
                            {get(this, 'props.messages.divergentRequest')}
                        </div>

                        <Option>
                            <label>{get(this, 'props.messages.prepaid')} / {get(this, 'props.messages.subscriptionBased')}</label>
                            <br />
                            <Slider min={0} max={1} step={monthlyRatioSteps} value={subscriptionRatio} onChange={this.onSliderChange('subscriptionRatio')} />
                            <output>{Math.round(subscriptionRatio * 100)} % {get(this, 'props.messages.subscriptionBased')}</output>
                        </Option>

                        <FinalPricing
                            messages={this.props.messages}
                            amount={amount}
                            monthly={monthly}
                            base={base}
                            monthlyPayments={monthlyPayments}
                            amounts={amounts}
                            value={value}
                            currency={currency} />

                        <button className={globalStyles.button} onClick={this.download}>
                            {get(this, 'props.messages.costIndication')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

DesktopColumn.propTypes = {
    messages: propTypes.object.isRequired,
    onLoading: propTypes.func.isRequired
}

DesktopColumn.defaultProps = {

}

export default DesktopColumn;
