module.exports = {
    "currency": "€",
    "loaderColor": "#e84d49",
    "subscriptionEquivelantPriceInMonths": 24,
    "defaultSubscriptionRatio": 0,
    "website": {
        // "websiteColor": "#38c8fe",
        // "titleColor": "#ffffff",
        "monthlyPayments": {
            "advancedSpamProtection": true
        },
        "base": {
            "once": 400,
            "monthly": 10
        },
        "amounts": {
            "complexity": 50,
            "domains": 100,
            "customFeatures": 200,
            "customDomainEmail": 100,
            "advancedSpamProtection": 50,
            "pushNotifications": 100,
            "emailTemplate": 150,
            "emailSignature": 200,
            "mailingList": 150,
            "blogging": 100,
            "seo": 50,
            "socialMediaManagment": 500,
            "googleBusiness": 50,
            "virtualAssisentChat": 300,
            "logoDesign": 200
        },
        "value": {
            "logoDesign": false,
            "complexity": 0,
            "domains": 1,
            "customFeatures": 0,
            "customDomainEmail": false,
            "advancedSpamProtection": false,
            "pushNotifications": false,
            "emailTemplate": false,
            "emailSignature": false,
            "mailingList": false,
            "blogging": false,
            "seo": false,
            "socialMediaManagment": false,
            "googleBusiness": false,
            "virtualAssisentChat": false
        }
    },
    "desktop": {
        "monthlyPayments": {
        },
        // "desktopColor": "#e84d49",
        // "titleColor": "#ffffff",
        "base": {
            "once": 2000,
            "monthly": 50
        },
        "amounts": {
            "complexity": 100,
            "wordpressSite": -100,
            "pushNotifications": 200,
            "native": 1000,
            "database": 500,
            "auth": 300,
            "realTimeUpdates": 1000,
            "chat": 1000,
            "camera": 300,
            "bluetooth": 800,
            "googleMaps": 100,
            "serviceIntegrations": 200,
            "backgroundRunning": 300,
            "geolocation": 300,
            "microsoftStore": 300,
            "inAppPurchases": 500,
            "productDevelopment": 500,
            "customFeatures": 300,
            "alreadyHaveAnApp": 0,
            "iosDeployment": 1000,
            "linuxDeployment": 1000
        },
        "value": {
            "complexity": 0,
            "wordpressSite": false,
            "pushNotifications": false,
            "native": false,
            "database": false,
            "auth": false,
            "realTimeUpdates": false,
            "chat": false,
            "camera": false,
            "bluetooth": false,
            "googleMaps": false,
            "serviceIntegrations": 0,
            "backgroundRunning": false,
            "geolocation": false,
            "microsoftStore": false,
            "inAppPurchases": false,
            "productDevelopment": false,
            "customFeatures": 0,
            "alreadyHaveAnApp": true,
            "iosDeployment": false,
            "linuxDeployment": false
        }
    },
    "app": {
        "monthlyPayments": {
        },
        // "appColor": "#f5a000",
        // "titleColor": "#ffffff",
        "base": {
            "once": 1200,
            "monthly": 20
        },
        "amounts": {
            "complexity": 20,
            "wordpressSite": -100,
            "pushNotifications": 200,
            "pages": 100,
            "native": 1000,
            "database": 500,
            "auth": 300,
            "realTimeUpdates": 1000,
            "chat": 1000,
            "camera": 300,
            "bluetooth": 800,
            "googleMaps": 100,
            "serviceIntegrations": 200,
            "backgroundRunning": 300,
            "geolocation": 300,
            "microsoftStore": 300,
            "inAppPurchases": 500,
            "productDevelopment": 500,
            "customFeatures": 200
        },
        "value": {
            "complexity": 0,
            "wordpressSite": false,
            "pushNotifications": false,
            "native": false,
            "database": false,
            "auth": false,
            "realTimeUpdates": false,
            "chat": false,
            "camera": false,
            "bluetooth": false,
            "googleMaps": false,
            "serviceIntegrations": 0,
            "backgroundRunning": false,
            "geolocation": false,
            "microsoftStore": false,
            "inAppPurchases": false,
            "productDevelopment": false,
            "pages": 3,
            "customFeatures": 0
        }
    }
}
