import logo from '../ess.png'
import saveAs from 'file-saver';

export const download = async ({
    price,
    body = ''
}) => {
    const date = new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();
    const logoUrl = new window.URL(logo, window.location.href).href;
    console.log(logoUrl)

    const payload = {
        "logo": logoUrl,
        "structure": {
            "body": "Hierbij stuur ik u de prijsindicatie voor de website. " + body,
            "prijs": price,
            "title": "Kostenindicatie",
            "betreft": "Website",
            "aangemaakt": date,
            "opmerkingen": "Prijzen zijn geldig gedurende een maand na datum en excl. BTW tenzij anders vermeld.",
            "ontvangen_van": {
                "tel": "0640566004",
                "land": "Nederland",
                "naam": "Sem Postma",
                "adres": "Aletta Jacobspad 20",
                "email": "info@esstudio.nl",
                "btw_nr": "NL228836554B01",
                "kvk_nr": "75086972",
                "plaats": "Oosterhout",
                "website": {
                    "href": "https://esstudio.nl",
                    "label": "esstudio.nl"
                },
                "postcode": "4901 MN",
                "bedrijfs_naam": "Echo Sierra Studio"
            }
        }
    }

    const response = await fetch(`https://api.esstudio.site/api/administration/documents/generate-immediate/kostenindicatie.pdf`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    const blob = await response.blob();

    saveAs(blob, 'kostenindicatie.pdf');
}
