import React, { Component } from 'react'; import { h } from 'preact';
import globalStyles from '../../index.module.css';
class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={globalStyles.Feature}>
                {this.props.children}
            </div>
        );
    }
}

Feature.propTypes = {

}

Feature.defaultProps = {

}

export default Feature;
