import config from '../config'

export const getPrice = (amounts, currency, value, monthlyPayments, base, subscriptionRatio = 0.5) => {

    let amount = base.once;
    let monthly = base.monthly;

    Object.keys(amounts).forEach(key => {
        const add = x => {
            if (monthlyPayments[key]) {
                monthly += x
            } else {
                amount += x
            }
            if (isNaN(amount)) throw new Error(key)
        }
        if (value[key] === true) {
            add(amounts[key]);
        } else if (typeof value[key] === 'number') {
            add(amounts[key] * value[key]);
        }
    });

    monthly += Math.ceil((amount * subscriptionRatio) / config.subscriptionEquivelantPriceInMonths)
    amount = Math.ceil(amount *= 1 - subscriptionRatio)

    return { amount, monthly }
}