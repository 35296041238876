import React, { Component } from 'react'; import { h } from 'preact';
import styles from './ColumnHeader.module.css';
import propTypes from 'prop-types';

class ColumnHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { style, title, subTitle } = this.props;

        return (
            <div style={style} className={styles.ColumnHeader}>
                {title}<br /><br />
                <small>{subTitle}</small>
            </div>
        );
    }
}

ColumnHeader.propTypes = {
    style: propTypes.object,
    title: propTypes.string,
}

ColumnHeader.defaultProps = {

}

export default ColumnHeader;
