import React, { Component } from 'react'; import { h } from 'preact';
import styles from './AppColumn.module.css';
import propTypes from 'prop-types';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import Option from '../Option/Option';
import Highlight from '../Highlight/Highlight';
import Feature from '../Feature/Feature';
import Slider, { Range } from 'rc-slider';
import get from 'lodash/get';
import set from 'lodash/set';
import FinalPricing from '../FinalPricing/FinalPricing';
import { download } from '../../services/kostenindicatie';
import { getPrice } from '../../lib/pricing';
import globalStyles from '../../index.module.css';
import config from '../../config'
import { supplant } from '../../_locales';
import { decamalize, capatalize } from '../../lib/util';
import { createText } from '../../lib/markdown'

const { currency, app: { titleColor, appColor, monthlyPayments, base, value, amounts } } = config

class AppColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value,
            subscriptionRatio: config.defaultSubscriptionRatio
        };

    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    onSliderChange = path => value => {
        console.log(value)
        this.setState(prevState => {
            set(prevState, path, value);
            console.log(prevState, path, value)
            return prevState;
        });
    }

    onChange = path => e => {
        const { value } = e.target;
        this.setState(prevState => {
            set(prevState, path, value)
            console.log(prevState)
            return prevState;
        });
    }

    download = async () => {
        const { subscriptionRatio } = this.state
        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, subscriptionRatio)

        const messages = get(this, 'props.messages', {})
        const monthlyCostsDisclaimerTemplate = get(this, 'props.messages.monthlyCostsDisclaimer')
        const monthlyCostsDisclaimer = supplant(monthlyCostsDisclaimerTemplate, { currency, monthly })
        const text = createText(messages, value, 'app')

        let body = '';
        if (monthly) body += monthlyCostsDisclaimer
        body += '\n\n'
        body += text;

        this.props.onLoading(download({ price: amount, body }), get(this, 'props.messages.downloadingKostenindicatie'));
    }

    onCheckboxChange = path => e => {
        const { checked } = e.target;
        console.log(checked);
        this.setState(prevState => {
            set(prevState, path, checked)
            if (prevState.chat === true) prevState.realTimeUpdates = true;
            if (prevState.chat === true) prevState.auth = true;
            return prevState;
        });
    }

    render() {
        const { value, subscriptionRatio } = this.state;
        const { monthly: subscriptionMonthly } = getPrice(amounts, currency, value, monthlyPayments, base, 1)
        const monthlyRatioSteps = 1 / Math.min(subscriptionMonthly, 100)
        
        let complexityLabel = get(this, 'props.messages.templateWithoutChanges')
        if (value.complexity >= 20) {
            complexityLabel = get(this, 'props.messages.templateWithASmallAmountOfChanges')
        } if (value.complexity >= 40) {
            complexityLabel = get(this, 'props.messages.aCustomAppDesignedFromATemplate')
        } if (value.complexity >= 60) {
            complexityLabel = get(this, 'props.messages.ACustomAppDesignedWithoutATemplate')
        } if (value.complexity >= 80) {
            complexityLabel = get(this, 'props.messages.fullyCustomApp')
        }

        const { monthly, amount } = getPrice(amounts, currency, value, monthlyPayments, base, subscriptionRatio)

        return (
            <div className={styles.WebsiteColumn}>
                <div className={globalStyles.inner}>
                    <ColumnHeader style={{ backgroundColor: appColor, color: titleColor }} title={get(this, 'props.messages.appTitle')} subTitle={get(this, 'props.messages.appSubTitle')} />

                    <div className={globalStyles.scrollPaneShadow} >

                        <div className={globalStyles.scrollPane}>

                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.privacy')}</Highlight> {get(this, 'props.messages.policy')}
                                <br /><small>{get(this, 'props.messages.dontWorryAboutWritingYourOwnPrivacyPoliciy')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>Android</Highlight> {get(this, 'props.messages.appImplementationInTheGooglePlayStore')}
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>iOS</Highlight> {get(this, 'props.messages.appImplementationInTheAppStore')}
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                {get(this, 'props.messages.alsoWorks')} <Highlight>{get(this, 'props.messages.offline')}</Highlight>.
                            <br /><small>{get(this, 'props.messages.offlineDescription')}</small>
                            </Feature>
                            <Feature>
                                <small>{get(this, 'props.messages.free')}</small><br />
                                <Highlight>{get(this, 'props.messages.encryption')} </Highlight> {get(this, 'props.messages.exportCompliance')}
                                <br /><small>{get(this, 'props.messages.exportComplianceDescription')}</small>
                            </Feature>
                            <Option>
                                <small>{currency}{amounts.wordpressSite},-</small><br />
                                <label>{get(this, 'props.messages.appWordpressSite')}</label>
                                <input type="checkbox" checked={value.wordpressSite} onChange={this.onCheckboxChange('value.wordpressSite')} />
                                <br /><small>{get(this, 'props.messages.alreadyBuildAWebsiteByUs')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.pushNotifications},-</small><br />
                                <label>{get(this, 'props.messages.appPushNotifications')}</label>
                                <input type="checkbox" checked={value.pushNotifications} onChange={this.onCheckboxChange('value.pushNotifications')} label='Push notifications' />
                                <br /><small>{get(this, 'props.messages.sendPushNotificationsToUsers')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.native},-</small><br />
                                <label>{get(this, 'props.messages.appNative')}</label>
                                <input type="checkbox" checked={value.native} onChange={this.onCheckboxChange('value.native')} label='Native app' />
                                <br /> <small>{get(this, 'props.messages.appNativeDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.database},-</small><br />
                                <label>{get(this, 'props.messages.appDatabase')}</label>
                                <input type="checkbox" checked={value.database} onChange={this.onCheckboxChange('value.database')} label='Remote Database' />
                                <br /> <small>{get(this, 'props.messages.appDatabaseDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.auth},-</small><br />
                                <input type="checkbox" checked={value.auth} onChange={this.onCheckboxChange('value.auth')} label='Authentication' />
                                <br /> <small>{get(this, 'props.messages.appAuthDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.realTimeUpdates},-</small><br />
                                <label>{get(this, 'props.messages.appRealTimeUpdates')}</label>
                                <input type="checkbox" checked={value.realTimeUpdates} onChange={this.onCheckboxChange('value.realTimeUpdates')} label='Real time updates.' />
                                <br /> <small>{get(this, 'props.messages.appRealTimeUpdatesDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.chat},-</small><br />
                                <label>{get(this, 'props.messages.appChat')}</label>
                                <input type="checkbox" checked={value.chat} onChange={this.onCheckboxChange('value.chat')} label='Let users chat?' />
                                <br /> <small>{get(this, 'props.messages.appChatDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.camera},-</small><br />
                                <label>{get(this, 'props.messages.appCamera')}</label>
                                <input type="checkbox" checked={value.camera} onChange={this.onCheckboxChange('value.camera')} label='Camera' />
                                <br /> <small>{get(this, 'props.messages.appWebcamDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.bluetooth},-</small><br />
                                <label>{get(this, 'props.messages.appBluetooth')}</label>
                                <input type="checkbox" checked={value.bluetooth} onChange={this.onCheckboxChange('value.bluetooth')} label='Bluetooth' />
                                <br /> <small>{get(this, 'props.messages.appBluetoothDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.googleMaps},-</small><br />
                                <label>{get(this, 'props.messages.appGoogleMaps')}</label>
                                <input type="checkbox" checked={value.googleMaps} onChange={this.onCheckboxChange('value.googleMaps')} label='Maps' />
                                <br /> <small>{get(this, 'props.messages.appGoogleMapsDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.backgroundRunning},-</small><br />
                                <label>{get(this, 'props.messages.appBackgroundRunning')}</label>
                                <input type="checkbox" checked={value.backgroundRunning} onChange={this.onCheckboxChange('value.backgroundRunning')} label='Achtergrondprocessen' />
                                <br /> <small>{get(this, 'props.messages.appBackgroundTasksDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.geolocation},-</small><br />
                                <label>{get(this, 'props.messages.appGeolocation')}</label>
                                <input type="checkbox" checked={value.geolocation} onChange={this.onCheckboxChange('value.geolocation')} label='Geolocation' />
                                <br /> <small>{get(this, 'props.messages.appGpsDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.microsoftStore},-</small><br />
                                <label>{get(this, 'props.messages.appMicrosoftStore')}</label>
                                <input type="checkbox" checked={value.microsoftStore} onChange={this.onCheckboxChange('value.microsoftStore')} label='Microsoft Store' />
                                <br /> <small>{get(this, 'props.messages.appMicrosoftStoreDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.inAppPurchases},-</small><br />
                                <label>{get(this, 'props.messages.appInAppPurchases')}</label>
                                <input type="checkbox" checked={value.inAppPurchases} onChange={this.onCheckboxChange('value.inAppPurchases')} label='In-app purchases' />
                                <br /> <small>{get(this, 'props.messages.appInAppPurchasesDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.productDevelopment},-</small><br />
                                <label>{get(this, 'props.messages.appProductDevelopment')}</label>
                                <input type="checkbox" checked={value.productDevelopment} onChange={this.onCheckboxChange('value.productDevelopment')} label='Product development' />
                                <br /> <small>{get(this, 'props.messages.appProductDevelopmentDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.pages},- / {get(this, 'props.messages.perPage')}</small><br />
                                <label>{get(this, 'props.messages.appPages')}</label>
                                <Slider min={1} max={10} value={value.pages} onChange={this.onSliderChange('value.pages')} />
                                <output>{value.pages}</output> <small>{get(this, 'props.messages.appPagesDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.serviceIntegrations},- / {get(this, 'props.messages.perIntegration')}</small><br />
                                <label>{get(this, 'props.messages.appServiceIntegrations')}</label>
                                <Slider min={0} max={10} value={value.serviceIntegrations} onChange={this.onSliderChange('value.serviceIntegrations')} />
                                <output>{value.serviceIntegrations}</output><br />
                                <small>{get(this, 'props.messages.appServiceIntegrationsDescriptions')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.complexity * 20},- / {get(this, 'props.messages.perStep')}</small><br />
                                <label>{get(this, 'props.messages.appComplexity')}</label>
                                <Slider step={20} min={0} max={100} value={value.complexity} onChange={this.onSliderChange('value.complexity')} />
                                <br />
                                <output>{complexityLabel}</output> <small>{get(this, 'props.messages.appComplexityDescription')}</small>
                            </Option>
                            <Option>
                                <small>{currency}{amounts.customFeatures},- / per functie</small><br />
                                <label>{get(this, 'props.messages.appCustomFeatures')}</label>
                                <br />
                                <small></small>
                                <Slider min={0} max={10} value={value.customFeatures} onChange={this.onSliderChange('value.customFeatures')} />
                                <output>{value.customFeatures === 10 ? <Highlight>Onbeperkt</Highlight> : value.customFeatures} {get(this, 'props.messages.customFeatures')}</output>
                            </Option>

                        </div>
                    </div>

                </div>
                <div className={globalStyles.inner}>

                    <div className={globalStyles.padded}>
                        <br /><br />

                        <div>
                            {get(this, 'props.messages.supportAndExtraWorkPayment')}
                        </div>

                        <br />

                        <div>
                            {get(this, 'props.messages.thirdPartyCosts')}
                        </div>

                        <br /><br />

                        <div>
                            {get(this, 'props.messages.divergentRequest')}
                        </div>

                        <Option>
                            <label>{get(this, 'props.messages.prepaid')} / {get(this, 'props.messages.subscriptionBased')}</label>
                            <br />
                            <Slider min={0} max={1} step={monthlyRatioSteps} value={subscriptionRatio} onChange={this.onSliderChange('subscriptionRatio')} />
                            <output>{Math.round(subscriptionRatio * 100)} % {get(this, 'props.messages.subscriptionBased')}</output>
                        </Option>

                        <FinalPricing
                            messages={this.props.messages}
                            amount={amount}
                            monthly={monthly}
                            base={base}
                            monthlyPayments={monthlyPayments}
                            value={value}
                            amounts={amounts}
                            currency={currency} />

                        <button className={globalStyles.button} onClick={this.download}>
                            {get(this, 'props.messages.costIndication')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

AppColumn.propTypes = {
    messages: propTypes.object.isRequired,
    onLoading: propTypes.func.isRequired
}

AppColumn.defaultProps = {

}

export default AppColumn;
