const en = import('./messages/en.json')
const nl = import('./messages/nl.json')

export const loadLocale = (lang) => {
    const code = lang.split('-')[0]

    switch (code) {
        case 'nl':
            return Promise.resolve(nl)            
    
        default:
            return Promise.resolve(en)            
    }
}

export const defaultLocale = nl

export const supplant = function (str, o) {
    if (typeof str !== 'string') return ''
    return str.replace(/{([^{}]*)}/g,
        function (a, b) {
            var r = o[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
};
