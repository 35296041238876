import React, { Component } from 'react'; import { h } from 'preact';
import styles from './FinalPricing.module.css';
import propTypes from 'prop-types';
import get from 'lodash/get'

class FinalPricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { currency, amount, monthly } = this.props;

        return (
            <div className={styles.FinalPricing}>
                {currency}{amount}<br />
                {currency}{monthly} <small>/ {get(this, 'props.messages.perMonthFinalPricing')}</small>
            </div>
        );
    }
}

FinalPricing.propTypes = {
    value: propTypes.object.isRequired,
    currency: propTypes.string.isRequired,
    amounts: propTypes.object.isRequired,
    monthlyPayments: propTypes.object.isRequired,
    base: propTypes.shape({ once: propTypes.number.isRequired, monthly: propTypes.number.isRequired }).isRequired
}

FinalPricing.defaultProps = {

}

export default FinalPricing;
