import React from 'react'
import { h } from 'preact'
import styles from './Overlay.module.css'
import propTypes from 'prop-types'

const cl = (...args) => args.filter(Boolean).join(' ')

const Overlay = props => {
    return <div className={cl(props.active ? styles.active : styles.notActive, styles.Overlay)}>
        {props.children}
        <div className={styles.panel}>{props.overlay}</div>
    </div>
}

Overlay.propTypes = {
    active: propTypes.bool,
    overlay: propTypes.any.isRequired,
    children: propTypes.any.isRequired
}

Overlay.defaultValues = {
    active: false
}

export default Overlay
